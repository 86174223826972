import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import useAsyncOperationWithErrorHandling from "../../../common/Hooks/useAsyncOperationWithErrorHandling";
import classes from "./CamerasParameters.module.css";
import * as api from "../../cameras/camerasApi";
import { selectSiteId } from "../../sites/SiteStore";
import { ICameraMetadataWithParameters, ICameraParameters } from "../../cameras/camerasModels";

const createParametersPrototype = (): ICameraParameters => ({
  userName: "",
  password: "",
});

const CamerasParameters = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const [agentIds, setAgentIds] = useState<number[]>([]);
  const siteId = useSelector(selectSiteId);
  const [siteCameras, setSiteCameras] = useState<ICameraMetadataWithParameters[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const handleError = (message: string) => {
    setAlertMessage({
      text: message,
      severity: "error",
    });
  };
  const initAsync = async (setLoading: boolean) => {
    if (setLoading) {
      setIsLoading(true);
    }
    const [fetchedAgentIds, fetchedCameras] = await Promise.all([
      api.getSiteAgentsIds(siteId),
      api.getSiteCamerasParameters(siteId),
    ]);
    setAgentIds(fetchedAgentIds);
    setSiteCameras(fetchedCameras);
    if (setLoading) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initAsync(true);
  }, [siteId]);

  const runAsyncFuncWithErrorHandling = useAsyncOperationWithErrorHandling(handleError);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => {
    setAlertMessage(undefined);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id: GridRowId) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    initAsync(true);
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = async (newRow: GridRowModel<ICameraMetadataWithParameters>) => {
    const updatedRow = { ...newRow };
    setIsLoading(true);
    setAlertMessage({
      text: "Saving camera parameters...",
      severity: "info",
    });
    try {
      await runAsyncFuncWithErrorHandling(
        () =>
          api.setCameraParameters(siteId, {
            ...newRow.parameters,
            agentId: newRow.agentId,
            cameraId: newRow.id,
          }),
        "Failed to save camera parameters"
      );
      setAlertMessage({
        text: "Camera parameters saved successfully",
        severity: "success",
      });
      await initAsync(false);
    } finally {
      setIsLoading(false);
    }
    return updatedRow;
  };

  const columns: GridColDef<ICameraMetadataWithParameters>[] = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      editable: false,
      valueGetter: (params) => params.row.id,
    },
    { field: "name", headerName: "Camera Name", width: 200, editable: false },
    {
      field: "agentId",
      headerName: "Agent ID",
      width: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Unassigned", value: 0 },
        ...agentIds.map((id) => ({ label: id.toString(), value: id })),
      ],
      valueGetter: (params) => params.row.agentId ?? 0,
      valueSetter: (params) => {
        if (params.value === 0) {
          params.row.agentId = undefined;
        } else {
          params.row.agentId = params.value;
        }
        return params.row;
      },
    },
    {
      field: "userName",
      headerName: "User name",
      width: 200,
      editable: true,
      type: "string",
      valueGetter: (params) => params.row.parameters?.userName,
      valueSetter: (params) => {
        if (!params.row.parameters) {
          params.row.parameters = createParametersPrototype();
        }
        params.row.parameters.userName = params.value;
        return params.row;
      },
    },
    {
      field: "password",
      headerName: "Password",
      width: 200,
      editable: true,
      type: "string",
      renderCell: (params) => (params.row.parameters?.password ? "********" : ""),
      valueGetter: (params) => params.row.parameters?.password,
      valueSetter: (params) => {
        if (!params.row.parameters) {
          params.row.parameters = createParametersPrototype();
        }
        params.row.parameters.password = params.value;
        return params.row;
      },
    },
    {
      field: "controlPortOverride",
      headerName: "Control Port Override",
      width: 100,
      editable: true,
      type: "number",
      valueGetter: (params) => params.row.parameters?.controlPortOverride,
      valueSetter: (params) => {
        if (!params.row.parameters) {
          params.row.parameters = createParametersPrototype();
        }
        params.row.parameters.controlPortOverride = params.value;
        return params.row;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key="save"
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={() => handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key="cancel"
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={() => handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            key="edit"
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className={classes["container"]}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <Box sx={{ height: 500, width: "100%" }}>
        <DataGrid
          loading={isLoading}
          rows={siteCameras}
          rowSelection={false}
          editMode="row"
          rowModesModel={rowModesModel}
          processRowUpdate={processRowUpdate}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          columns={columns}
          localeText={{
            noRowsLabel: "No cameras found for the site",
          }}
        ></DataGrid>
      </Box>
    </div>
  );
};

export default CamerasParameters;
