import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DevicesMap, ISiteMetadata } from "./SiteModels";
import { RootState } from "../app/Store";
import { DateTimeFormatter } from "../app/DateTimeFormatter";
import { resetSiteSpecificData } from "../app/store/GlobalActions";

export const defaultProperties: ISiteMetadata = {
  siteId: "",
  trackers: {},
  weatherSensors: {},
  inverters: {},
  powerMeters: {},
  agriSensors: {},
  ianaTimeZoneName: "",
  upsExists: false,
  visualInfo: {
    displayName: "",
    isAgriPageVisible: false,
    isEnableUserRecordsFeature: false,
    isEnableSprinklersFeature: false,
    isEnableWeatherFeature: false,
    isEnableQueryHistoryForMoreThanOneWeek: false,
    isEnableIssuesFeature: false,
  },
  tags: {},
  tagAliases: {},
  sprinklersIds: [],
  cameras: [],
};

export const siteSlice = createSlice({
  name: "site",
  initialState: defaultProperties,
  reducers: {
    setSiteMetadata: (state: ISiteMetadata, action: PayloadAction<ISiteMetadata>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetSiteSpecificData, () => {
      return defaultProperties;
    });
  },
});

export const siteReducer = siteSlice.reducer;

export const selectSiteId = createSelector(
  (state: RootState) => state.site,
  (siteSlice) => siteSlice.siteId
);

export const selectSiteDateTimeFormatter = createSelector(
  (state: RootState) => state.site,
  (siteSlice) => new DateTimeFormatter(siteSlice.ianaTimeZoneName)
);

export const selectTrackerIds = createSelector((state: RootState) => state.site.trackers, getDeviceIds);

export const selectInverterIds = createSelector(
  (state: RootState) => state.site.inverters,
  getDeviceIds
);

export const selectSiteTrackersMap = createSelector(
  (state: RootState) => state.site.trackers,
  (x) => x
);

function getDeviceIds<T>(map: DevicesMap<T>) {
  return Object.keys(map).map((t) => parseInt(t, 10));
}
