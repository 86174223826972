import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaultsMetadata } from "../app/store/FaultsStore";
import { fetchSitesMetadata } from "../app/store/SitesStore";
import { AppDispatch, RootState } from "../app/Store";
import { LoadingState } from "../app/LoadingState";

const useSetupTagsInfo = ({ siteIds }: { siteIds: string[] }) => {
  const dispatch = useDispatch<AppDispatch>();
  const loadingFaultsMetadata = useSelector(
    (state: RootState) => state.multiSitesFaults.metadata.loading
  );
  const loadingSitesMetadata = useSelector((state: RootState) => state.multiSitesMetadata.loading);

  useEffect(() => {
    if (siteIds.length > 0) {
      dispatch(fetchSitesMetadata(siteIds));
      dispatch(fetchFaultsMetadata());
    }
  }, [siteIds]);
  let error: string | undefined;
  if (loadingSitesMetadata === LoadingState.Error) {
    error = "Failed to load sites metadata";
  } else if (loadingFaultsMetadata === LoadingState.Error) {
    error = "Failed to load faults metadata";
  }
  return {
    isLoading:
      loadingSitesMetadata == LoadingState.Pending || loadingFaultsMetadata === LoadingState.Pending,
    error,
  };
};
export default useSetupTagsInfo;
