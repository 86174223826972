import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/Store";
import { ComponentType, UrgencyLevel } from "../faults/DTOs";
import { ActiveIssuesByComponentCategory } from "../faults/Models";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import { selectActiveIssuesByComponentCategory } from "../app/store/FaultsStore";
import { LoadingState } from "../app/LoadingState";

interface SystemStatusDescription {
  invertersIssusDescription: string;
  trackersIssuesDescription: string;
}

export const useSystemStatusDescription = (siteId: string): SystemStatusDescription => {
  const siteActiveFaultsLoadingState = useSelector(
    (state: RootState) => state.multiSitesFaults.activeFaults[siteId]?.issues?.loadingState
  );

  const siteActiveIssuesByCategory = useSelector((state: RootState) =>
    selectActiveIssuesByComponentCategory(state, siteId)
  );
  const trackers = useSelector((state: RootState) => state.multiSitesMetadata.sites[siteId].trackers);
  const totalNumOfTrackers = Object.keys(trackers).length;
  const inverters = useSelector((state: RootState) => state.multiSitesMetadata.sites[siteId].inverters);
  const totalNumOfInverters = Object.keys(inverters).length;
  const [values, setValues] = useState<SystemStatusDescription>();

  useEffect(() => {
    const values = calculateDescriptions(
      siteActiveFaultsLoadingState,
      siteActiveIssuesByCategory,
      totalNumOfInverters,
      totalNumOfTrackers
    );
    setValues(values);
  }, [
    siteActiveFaultsLoadingState,
    siteActiveIssuesByCategory,
    totalNumOfTrackers,
    totalNumOfInverters,
  ]);

  return (
    values || {
      invertersIssusDescription: NO_VALUE_PLACEHOLDER,
      trackersIssuesDescription: NO_VALUE_PLACEHOLDER,
    }
  );
};

function calculateDescriptions(
  currentSiteIssuesLoadingState: LoadingState | undefined,
  currentSiteIssuesByCategory: ActiveIssuesByComponentCategory | undefined,
  totalNumOfInverters: number,
  totalNumOfTrackers: number
) {
  let invertersIssusDescription: string;
  let trackersIssuesDescription: string;

  if (
    currentSiteIssuesLoadingState === LoadingState.Complete &&
    currentSiteIssuesByCategory !== undefined
  ) {
    const trackerIssues = currentSiteIssuesByCategory[ComponentType.Tracker];
    const trackersHighSeverityIssues =
      trackerIssues?.filter((issueInfo) => issueInfo.urgencyLevel === UrgencyLevel.High) || [];
    const trackersHighSeverityIssuesCount = new Set(
      trackersHighSeverityIssues.map((issuesInfo) => issuesInfo.deviceId)
    ).size;
    const inverterIssues = currentSiteIssuesByCategory[ComponentType.Inverter];
    const invertersHighSeverityIssues =
      inverterIssues?.filter((issuesInfo) => issuesInfo.urgencyLevel === UrgencyLevel.High) || [];
    const invertersHighSeverityIssuesCount = new Set(
      invertersHighSeverityIssues.map((issuesInfo) => issuesInfo.deviceId)
    ).size;

    if (totalNumOfInverters == 0 || invertersHighSeverityIssuesCount == 0) {
      invertersIssusDescription = "No inverters' high-severity issues";
    } else {
      const invertersIssuesPrecentage = (
        (invertersHighSeverityIssuesCount / totalNumOfInverters) *
        100
      ).toFixed(0);
      invertersIssusDescription = `${invertersHighSeverityIssuesCount} of ${totalNumOfInverters} (${invertersIssuesPrecentage}%) have high severity issues`;
    }

    if (totalNumOfTrackers == 0 || trackersHighSeverityIssuesCount == 0) {
      trackersIssuesDescription = "No trackers' high-severity issues";
    } else {
      const trackersIssuesPrecentage = (
        (trackersHighSeverityIssuesCount / totalNumOfTrackers) *
        100
      ).toFixed(0);
      trackersIssuesDescription = `${trackersHighSeverityIssuesCount} of ${totalNumOfTrackers} (${trackersIssuesPrecentage}%) have high severity issues`;
    }
  } else if (currentSiteIssuesLoadingState === LoadingState.Error) {
    trackersIssuesDescription = invertersIssusDescription = NO_VALUE_PLACEHOLDER;
  } else {
    invertersIssusDescription = trackersIssuesDescription = "⏳ Loading...";
  }
  return { invertersIssusDescription, trackersIssuesDescription };
}
