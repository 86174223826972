export interface ConfigNameAndDescription {
  name: string;
  description: string;
}

export interface AppVersionViewModel {
  dtoVersion: string;
  uiVersion: string;
  description?: string;
  isActive?: boolean;
  dateStr: string;
}

export enum SiteAppModule {
  Mcs = "Mcs",
  Das = "Das",
  TagsHarvester = "TagsHarvester",
  CamerasControl = "CamerasControl",
  Redis = "Redis",
}

export enum ModuleRuntimeStatus {
  NotExist = "NotExist",
  Running = "Running",
  Stopped = "Stopped",
  Failed = "Failed",
  Backoff = "Backoff",
  Unhealthy = "Unhealthy",
}
