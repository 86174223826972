import { PageNames } from "../../../pages/PageNames";
import { UserAccessType } from "../../user/UserStore";
import { NavConfig } from "../NavigationDrawer/NavigationDrawerModels";
import {
  AgriIcon,
  ConfigurationIcon,
  DashboardIcon,
  HistoryIcon,
  IssuesIcon,
  SprinklersIcon,
  TrackersIcon,
  UserRecordsIcon,
  WeatherIcon,
  CamerasIcon,
} from "../../../SolarGikLib/icons/common/navigation/NavIcons";
import { FeatureFlag } from "../../featureFlags/FeatureFlagsModels";

export const NavItemsConfig: NavConfig[] = [
  {
    pageName: "Dashboard",
    pagePath: "/" + PageNames.Dashboard,
    icon: DashboardIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.ViewDashboard,
  },
  {
    pageName: "History",
    pagePath: "/" + PageNames.History,
    icon: HistoryIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.Viewer,
  },
  {
    pageName: "Trackers",
    pagePath: "/" + PageNames.Trackers,
    icon: TrackersIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.Viewer,
  },
  {
    pageName: "User Records",
    pagePath: "/" + PageNames.UserRecords,
    icon: UserRecordsIcon,
    visiblityFilter: ({ userAccessType, siteMetadata }) =>
      userAccessType >= UserAccessType.Viewer && siteMetadata.visualInfo.isEnableUserRecordsFeature,
  },
  {
    pageName: "Agriculture",
    pagePath: "/" + PageNames.Agriculture,
    icon: AgriIcon,
    visiblityFilter: ({ userAccessType, siteMetadata }) =>
      userAccessType >= UserAccessType.Viewer && siteMetadata.visualInfo.isAgriPageVisible,
  },
  {
    pageName: "Alerts",
    pagePath: "/" + PageNames.Alerts,
    icon: IssuesIcon,
    visiblityFilter: ({ userAccessType, siteMetadata }) =>
      userAccessType >= UserAccessType.Viewer && siteMetadata.visualInfo.isEnableIssuesFeature,
  },
  {
    pageName: "Sprinklers",
    pagePath: "/" + PageNames.Sprinklers,
    icon: SprinklersIcon,
    visiblityFilter: ({ userAccessType, siteMetadata }) =>
      userAccessType >= UserAccessType.Viewer && siteMetadata.visualInfo.isEnableSprinklersFeature,
  },
  {
    pageName: "Weather",
    pagePath: "/" + PageNames.Weather,
    icon: WeatherIcon,
    visiblityFilter: ({ userAccessType, siteMetadata }) =>
      userAccessType >= UserAccessType.Engineer && siteMetadata.visualInfo.isEnableWeatherFeature,
  },
  {
    pageName: "Config",
    pagePath: "/" + PageNames.Configuration,
    icon: ConfigurationIcon,
    visiblityFilter: ({ userAccessType }) => userAccessType >= UserAccessType.Engineer,
  },
  {
    pageName: "Cameras",
    pagePath: "/" + PageNames.Cameras,
    icon: CamerasIcon,
    visiblityFilter: ({ userAccessType, siteMetadata, siteFeatureFlags }) =>
      userAccessType >= UserAccessType.Viewer &&
      siteFeatureFlags.some((flag) => flag === FeatureFlag.CamerasPage) &&
      siteMetadata.cameras.length > 0,
  },
];
