import {
  Autocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderOptionState,
  Paper,
} from "@mui/material";

import { autocompleteDropdownStyle } from "./AutocompleteStyle";
import { TextEnum } from "../TextStyles";

interface IAutocompleteProps<T> {
  options: T[];
  popUpIcon?: React.ReactNode | null;
  key: React.Key;
  id: string;
  renderInputFunc: (params: object) => JSX.Element;
  onChange: MuiAutocompleteProps<T, undefined, undefined, undefined>["onChange"];
  value?: T;
  textSize?: TextEnum | string;
  hidden?: boolean;
  disabled?: boolean;
  isDropdownWidthFitContent?: boolean;
  size?: "small" | "medium";
  getOptionLabel?: (option: T) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  width?: string;
  ListboxProps?: object;
}

const SolarGikAutocomplete = <T,>({
  options,
  popUpIcon,
  key,
  id,
  onChange,
  value = undefined,
  renderInputFunc,
  hidden,
  disabled = false,
  textSize = TextEnum.h4,
  size = "medium",
  renderOption,
  getOptionLabel,
  isDropdownWidthFitContent = false,
  width = "100%",
  ListboxProps,
}: Readonly<IAutocompleteProps<T>>) => {
  return (
    <Autocomplete
      sx={{ width: width }}
      options={options}
      renderInput={renderInputFunc}
      key={key}
      id={id}
      getOptionLabel={getOptionLabel}
      PaperComponent={(props) => (
        <Paper
          sx={{
            ...autocompleteDropdownStyle,
            ...(isDropdownWidthFitContent && { width: "fit-content" }),
          }}
          {...props}
        />
      )}
      popupIcon={popUpIcon}
      onChange={onChange}
      hidden={hidden}
      disableClearable={true}
      classes={{ input: textSize, listbox: textSize }}
      disabled={disabled}
      size={size}
      value={(value as T) ?? undefined}
      renderOption={renderOption}
      ListboxProps={ListboxProps}
    />
  );
};

export default SolarGikAutocomplete;
