import axios from "axios";

import { ConfigNameAndDescription, SiteAppModule } from "./AppConfigModels";
import APP_CONFIG from "../app/configuration/AppConfig";

const baseUrl = APP_CONFIG.serviceUrls.siteAppsConfigUrlPrefix;

export async function getConfigListAsync(siteId: string, configVersion: string, siteApp: SiteAppModule) {
  const url = `${baseUrl}/Query`;
  const params = {
    siteId: siteId,
    configVersionFilter: configVersion,
    siteApp: siteApp,
  };
  const httpResponse = await axios.request<ConfigNameAndDescription[]>({
    url,
    params,
  });
  return httpResponse.data;
}

export async function getConfigResourceAsync(resource: string, siteApp: SiteAppModule) {
  const url = `${baseUrl}/Resource/${resource}/${siteApp}`;
  const httpResponse = await axios.request<Uint8Array>({
    url,
    responseType: "arraybuffer",
  });
  return httpResponse.data;
}

export async function getActiveConfigAsync(siteId: string, siteApp: SiteAppModule) {
  const url = `${baseUrl}/Active/${siteId}/${siteApp}`;
  const httpResponse = await axios.get<string>(url);
  return httpResponse.data;
}

//uploads new config file and returns the id of the new file
export async function uploadConfigResourceAsync(
  siteId: string,
  configSchemaVersion: string,
  fileData: Uint8Array,
  siteApp: SiteAppModule
) {
  const url = `${baseUrl}/Resource/${siteId}_${configSchemaVersion}/${siteApp}`;
  const params = { file: fileData };
  const headers = { headers: { "Content-Type": "multipart/form-data" } };
  const newFileId = await axios.post<string>(url, params, headers);
  return newFileId.data;
}

export async function setActiveConfigResourceAsync(resource: string, siteApp: SiteAppModule) {
  const url = `${baseUrl}/Active/${resource}/${siteApp}`;
  await axios.post<void>(url);
}

export async function updateConfigAsync(siteId: string) {
  const url = `${baseUrl}/SiteConfiguration/${siteId}`;
  return await axios.put(url, undefined);
}

export async function updateMetadataDescriptionAsync(
  description: string,
  resource: string,
  siteApp: SiteAppModule
) {
  const url = `${baseUrl}/Resource/Metadata/${resource}/${siteApp}`;
  const params = { description: description };
  return await axios.post(url, params);
}

export async function deleteConfigResourceAsync(resource: string, siteApp: SiteAppModule) {
  const url = `${baseUrl}/Resource/${resource}/${siteApp}`;
  return await axios.delete(url);
}
