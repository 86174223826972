import axios from "axios";

import {
  AlertTransition,
  IAlertsHistory,
  IFaultsMetadata,
  ISingleDeviceIssue,
  ISiteActiveAlerts,
  ISitesIssueCounters,
} from "./DTOs";
import APP_CONFIG from "../app/configuration/AppConfig";

export const getAlertsHistory = async (
  siteId: string,
  from: Date,
  to: Date,
  signal: AbortSignal
): Promise<IAlertsHistory> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${siteId}/alerts/history`;
  const httpResponse = await axios.get<IAlertsHistory>(url, {
    signal,
    params: { from, to },
  });
  return httpResponse.data;
};

export const getActiveIssues = async (
  siteId: string,
  signal: AbortSignal
): Promise<ISingleDeviceIssue[]> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${siteId}/issues/active`;
  const httpResponse = await axios.get<ISingleDeviceIssue[]>(url, { signal });
  return httpResponse.data;
};

export const getActiveAlerts = async (
  siteId: string,
  signal: AbortSignal
): Promise<ISiteActiveAlerts> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${siteId}/alerts/active`;
  const httpResponse = await axios.get<ISiteActiveAlerts>(url, { signal });
  return httpResponse.data;
};

export const getAlertTransitions = async (
  siteId: string,
  alertKind: "single-device" | "aggregated",
  alertId: number,
  signal: AbortSignal
): Promise<AlertTransition[]> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/${siteId}/alerts/${alertKind}/${alertId}/history`;
  const httpResponse = await axios.get<AlertTransition[]>(url, { signal });
  return httpResponse.data;
};

export const getSitesActiveIssuesCounters = async (
  signal: AbortSignal
): Promise<ISitesIssueCounters> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/issues/active/counters`;
  const httpResponse = await axios.get<ISitesIssueCounters>(url, { signal });
  return httpResponse.data;
};

export const getFaultsMetadata = async (): Promise<IFaultsMetadata> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/faults/metadata`;
  const httpResponse = await axios.get<IFaultsMetadata>(url);
  return httpResponse.data;
};
