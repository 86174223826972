import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/Store";
import classes from "./TrackerIssues.module.css";
import { ISingleDeviceIssue, UrgencyLevel } from "../faults/DTOs";
import LightTooltip from "../../SolarGikLib/tooltip/LightTooltip";
import TrackerTooltipContent from "./TrackerTooltipContent";
import { humanizeEnumValue } from "../../common/EnumUtils";

type ValidUrgencyLevels = UrgencyLevel.High | UrgencyLevel.Medium | UrgencyLevel.Low;

interface ITrackerIssuesProps {
  trackerName?: string;
  activeIssues?: ISingleDeviceIssue[];
}
const TrackerIssues: FC<ITrackerIssuesProps> = ({ trackerName, activeIssues }) => {
  const issuesMetadata = useSelector((state: RootState) => state.multiSitesFaults.metadata.issues);
  if (!issuesMetadata || !activeIssues) {
    return null;
  }

  const issuesInfo = {
    [UrgencyLevel.High]: {
      data: [] as string[],
      className: classes.high,
    },
    [UrgencyLevel.Medium]: {
      data: [] as string[],
      className: classes.medium,
    },
    [UrgencyLevel.Low]: {
      data: [] as string[],
      className: classes.low,
    },
  };
  const issuesBySeverity = activeIssues.reduce((accumulator, issue) => {
    const issueTypeName = issuesMetadata[issue.issueType].name;
    accumulator[issue.urgencyLevel as ValidUrgencyLevels].data.push(issueTypeName);
    return accumulator;
  }, issuesInfo);

  return (
    <div className={classes.row}>
      <div className={classes["name"]}>{trackerName}</div>
      <div className={classes["circles-container"]}>
        {[UrgencyLevel.High, UrgencyLevel.Medium, UrgencyLevel.Low].map((severity) => {
          const info = issuesBySeverity[severity as ValidUrgencyLevels];
          return (
            <>
              {info.data.length > 0 && (
                <LightTooltip
                  key={severity}
                  title={
                    <TrackerTooltipContent
                      header={humanizeEnumValue(UrgencyLevel[severity]) + " Severity"}
                      info={info.data}
                      customClass={info.className}
                    />
                  }
                >
                  <div className={`${classes.circle} ${info.className}`}>{info.data.length}</div>
                </LightTooltip>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};
export default TrackerIssues;
