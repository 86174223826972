import { useSelector, useDispatch } from "react-redux";
import Issues from "../features/faults/issues/Issues";
import SolarGikDropdown from "../SolarGikLib/fields/Dropdown";
import getIcons from "../SolarGikLib/icons/Icons";
import { FieldIcons, IconCategory } from "../SolarGikLib/icons/IconsModels";
import SgTabs from "../SolarGikLib/tabs/SgTabs";
import { ITab } from "../SolarGikLib/tabs/TabsModels";
import {
  AlertTabOptions,
  FaultsTabOptions as FaultsTabOption,
  FaultsTabOptions,
  setActiveTab,
  setAlertDropdownTabOption,
} from "../features/app/store/FaultsPageStore";
import { AppDispatch, RootState } from "../features/app/Store";
import { UserAccessType } from "../features/user/UserStore";
import ActiveAlerts from "../features/faults/alerts/ActiveAlerts";
import AlertsHistory from "../features/faults/alerts/HistoryAlerts";

const ArrowDown = getIcons(FieldIcons.ArrowDown, IconCategory.Fields);

const SelectedIcon = getIcons(FieldIcons.ListItemSelected, IconCategory.Fields);

SelectedIcon.defaultProps = {
  width: "0.7rem",
  height: "0.7rem",
};

const Faults = () => {
  const dispatch = useDispatch<AppDispatch>();
  const activeMainTab = useSelector((state: RootState) => state.faultsPage.activeTab);

  const setMainTab = (tab: string) => dispatch(setActiveTab(tab as FaultsTabOption));

  const alertDropdownTabOption = useSelector(
    (state: RootState) => state.faultsPage.alertDropdownTabOption
  );

  const user = useSelector((state: RootState) => state.user);

  const tabs: ITab[] = [
    {
      label: (
        <SolarGikDropdown
          icon={ArrowDown}
          title={
            alertDropdownTabOption === AlertTabOptions.Active ? "Alerts (Active)" : "Alerts (History)"
          }
          SelectedIcon={SelectedIcon}
          horizontal="center"
          selectedDefaultItemLabel={alertDropdownTabOption}
          menuCustomUi={{
            ".MuiPaper-root": {
              width: "9rem",
              marginTop: "1.5rem",
            },
          }}
          menuItems={[
            {
              label: "Active",
              onClick: () => dispatch(setAlertDropdownTabOption(AlertTabOptions.Active)),
            },
            {
              label: "History",
              disabled: user.userType <= UserAccessType.ViewDashboard,
              onClick: () => dispatch(setAlertDropdownTabOption(AlertTabOptions.History)),
            },
          ]}
        />
      ),
      key: FaultsTabOptions[FaultsTabOptions.Alerts],
      content: alertDropdownTabOption === AlertTabOptions.Active ? <ActiveAlerts /> : <AlertsHistory />,
    },
    {
      label: "Issues",
      key: FaultsTabOptions[FaultsTabOptions.Issues],
      content: <Issues />,
    },
  ];
  return <SgTabs tabs={tabs} activeTab={activeMainTab} setActiveTab={setMainTab} />;
};

export default Faults;
