import { Routes, Route } from "react-router-dom";

import Agriculture from "./Agriculture";
import CloudConfiguration from "./CloudConfiguration";
import Dashboard from "./Dashboard";
import History from "./History";
import Home from "./Home";
import Faults from "./Faults";
import { PageNames } from "./PageNames";
import SiteConfiguration from "./SiteConfiguration";
import Sprinklers from "./Sprinklers";
import Trackers from "./Trackers";
import Trends from "./Trends";
import Weather from "./Weather";
import MultiSiteTable from "../features/multi_sites/MultiSiteTable";
import MultisiteContentPageRouter from "../features/navigation/MultisiteContentPageRouter";
import UserRecords from "./UserRecords";
import Cameras from "./Cameras";
import ContentPageRouter from "../features/navigation/ContentPageRouter";

const PageRoutes = () => {
  return (
    <Routes>
      <Route
        path={`/:currentSite/${PageNames.Dashboard}`}
        element={
          <ContentPageRouter>
            <Dashboard />
          </ContentPageRouter>
        }
      ></Route>
      <Route
        path={`/:currentSite/${PageNames.Trackers}`}
        element={
          <ContentPageRouter>
            <Trackers />
          </ContentPageRouter>
        }
      ></Route>
      <Route
        path={`/:currentSite/${PageNames.History}`}
        element={
          <ContentPageRouter>
            <History />
          </ContentPageRouter>
        }
      ></Route>
      <Route
        path={`/:currentSite/${PageNames.Alerts}`}
        element={
          <ContentPageRouter>
            <Faults />
          </ContentPageRouter>
        }
      ></Route>
      <Route
        path={`/:currentSite/${PageNames.Agriculture}`}
        element={
          <ContentPageRouter>
            <Agriculture />
          </ContentPageRouter>
        }
      ></Route>
      <Route
        path={`/:currentSite/${PageNames.Configuration}`}
        element={
          <ContentPageRouter>
            <SiteConfiguration />
          </ContentPageRouter>
        }
      ></Route>
      <Route
        path={`/:currentSite/${PageNames.Sprinklers}`}
        element={
          <ContentPageRouter>
            <Sprinklers />
          </ContentPageRouter>
        }
      ></Route>
      <Route
        path={`/:currentSite/${PageNames.UserRecords}`}
        element={
          <ContentPageRouter>
            <UserRecords />
          </ContentPageRouter>
        }
      ></Route>
      <Route
        path={`/:currentSite/${PageNames.Weather}`}
        element={
          <ContentPageRouter>
            <Weather />
          </ContentPageRouter>
        }
      ></Route>
      <Route
        path={`/:currentSite/${PageNames.Cameras}`}
        element={
          <ContentPageRouter>
            <Cameras />
          </ContentPageRouter>
        }
      ></Route>
      <Route
        path={`/${PageNames.Trends}`}
        element={<MultisiteContentPageRouter content={<Trends />} />}
      ></Route>
      <Route
        path={`/${PageNames.CloudConfiguration}`}
        element={<MultisiteContentPageRouter content={<CloudConfiguration />} />}
      ></Route>
      <Route
        path={`/${PageNames.MultisiteTable}`}
        element={<MultisiteContentPageRouter content={<MultiSiteTable />} />}
      ></Route>
      <Route path="/" element={<Home />}></Route>
    </Routes>
  );
};

export default PageRoutes;
