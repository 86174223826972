import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { updateConfigAsync, uploadConfigResourceAsync } from "./AppConfigAPI";
import SiteAppConfigUploadPopup from "./SiteAppConfigUploadPopup";
import { errorHandlerFunc } from "../../common/CommunicationErrorHandling";
import { COMMUNICATION_ZIP_HEADER, ZIP_FILE_SUFFIX } from "../../common/ConstantValues";
import { AlertMessage } from "../../SolarGikLib/alerts/AlertModels";
import SolarGikButton from "../../SolarGikLib/Button";
import { outlinedWhiteButton } from "../../SolarGikLib/styles/ButtonsStyle";
import { RootState } from "../app/Store";
import { useDropZone } from "../html_elements/DropZone";
import { UserAccessType } from "../user/UserStore";
import SolarGikAlert from "../../SolarGikLib/alerts/Alert";
import { SiteAppModule } from "./AppConfigModels";
import { selectSiteId } from "../sites/SiteStore";

interface ISiteAppConfigUploadProps {
  getConfigListFunc: () => void;
  versionRegex: RegExp;
  updateRemoteDescription: (
    path: string,
    description: string,
    callGetConfig: boolean
  ) => Promise<boolean>;
  siteApp: SiteAppModule;
}

const SiteAppConfigUpload: FC<ISiteAppConfigUploadProps> = ({
  getConfigListFunc,
  versionRegex,
  updateRemoteDescription,
  siteApp,
}) => {
  const siteId = useSelector(selectSiteId);
  const userType: UserAccessType = useSelector((state: RootState) => state.user.userType);
  const [showDropZonePopup, setShowDropZonePopup] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const isDisable = userType < UserAccessType.Engineer;

  const updateConfig = async () => {
    try {
      await updateConfigAsync(siteId);
    } catch (error) {
      setAlertMessage({
        text: errorHandlerFunc(error, "Update configuration"),
        severity: "error",
      });
    }
  };

  const validateVersionInput = (version: string) => {
    if (!version) {
      setAlertMessage({
        text: "Version is required",
        severity: "info",
      });
      return false;
    }
    if (!versionRegex.test(version)) {
      setAlertMessage({
        text: "Version format is not valid",
        severity: "error",
      });
      return false;
    } else {
      return true;
    }
  };

  const uploadFileHandler = async (version: string) => {
    let newFileName;
    try {
      setAlertMessage(undefined);
    } catch (error) {
      console.error(error);
    }
    if (fileContent.current) {
      try {
        newFileName = await uploadConfigResourceAsync(
          siteId,
          version,
          new Uint8Array(fileContent.current),
          siteApp
        );
        if (newFileName) {
          setAlertMessage({
            text: "File uploaded successfully",
            severity: "success",
          });
        }
      } catch (error) {
        setAlertMessage({
          text: errorHandlerFunc(error, "UploadFile"),
          severity: "error",
        });
      }
    }
    return newFileName;
  };

  const onUploadSubmitHandler = async (version: string, description: string) => {
    setShowDropZonePopup(false);
    if (validateVersionInput(version)) {
      const path = await uploadFileHandler(version);
      if (path) {
        await updateRemoteDescription(path, description, false);
        getConfigListFunc();
      } else {
        setAlertMessage({
          text: "Failed to update description",
          severity: "error",
        });
      }
    }
  };

  const { getRootProps, getInputProps, loadedFileName, fileContent, error } = useDropZone(
    () => setShowDropZonePopup(true),
    COMMUNICATION_ZIP_HEADER,
    ZIP_FILE_SUFFIX,
    isDisable
  );

  return (
    <>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <SolarGikButton
        style={outlinedWhiteButton}
        onClickFunc={updateConfig}
        isDisabled={isDisable}
        text={"Update configuration from SiteMetadata"}
      />
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <SolarGikButton
          style={outlinedWhiteButton}
          text={"Drag `n` drop or click to select a zip file"}
          isDisabled={isDisable}
        />
      </div>
      <p>{error.current?.message}</p>
      {showDropZonePopup && (
        <SiteAppConfigUploadPopup
          loadedFileName={loadedFileName}
          onSubmitHandler={onUploadSubmitHandler}
          setShowDropZonePopup={setShowDropZonePopup}
        />
      )}
    </>
  );
};
export default SiteAppConfigUpload;
