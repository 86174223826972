import { AppVersionViewModel, ConfigNameAndDescription } from "./AppConfigModels";

export const formatVersions = (versionList: ConfigNameAndDescription[]) => {
  const result: AppVersionViewModel[] = [];
  if (versionList.length > 0) {
    for (let i = 0; i < versionList.length; i++) {
      const version = versionList[i];
      const regex = /(?<Version>[\d.]+)_(?<Date>\d{4}-\d{2}-\d{2})T(?<Time>\d+)/;
      const regexResult = version.name.match(regex);
      if (regexResult?.groups) {
        const formattedTime = regexResult.groups.Time.replace(/(.{2})/g, "$1:").slice(0, -1);
        const dateStr = `${regexResult.groups.Date} ${formattedTime}`;
        const uiVersion = `v${regexResult.groups.Version} [${dateStr}]`;
        result.push({
          dtoVersion: version.name,
          uiVersion: uiVersion,
          description: version.description,
          dateStr: dateStr,
        });
      } else {
        const error = `Invalid version format of site configuration file. version: ${version}`;
        throw new Error(error);
      }
    }
    result.sort(compareAppVersionViewModelByDateStr);
  }
  return result;
};

const compareAppVersionViewModelByDateStr = (a: AppVersionViewModel, b: AppVersionViewModel) => {
  if (a.dateStr < b.dateStr) {
    return 1;
  }
  if (a.dateStr > b.dateStr) {
    return -1;
  }
  return 0;
};

export const versionRegex = /^(\d+(\.\d+)+)$/;
