import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classes from "./Issues.module.css";
import { RootState } from "../../app/Store";
import IssuesSection from "./IssuesSection";
import { ComponentCategory } from "../Models";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { selectSiteId } from "../../sites/SiteStore";
import { selectActiveIssuesByComponentCategory } from "../../app/store/FaultsStore";
import { LoadingState } from "../../app/LoadingState";

const Issues = () => {
  const siteId = useSelector(selectSiteId);
  const loadingState = useSelector(
    (state: RootState) => state.multiSitesFaults.activeFaults[siteId]?.issues?.loadingState
  );
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

  const siteActiveIssuesByCategory = useSelector((state: RootState) =>
    selectActiveIssuesByComponentCategory(state, siteId)
  );

  useEffect(() => {
    if (loadingState !== LoadingState.Error) {
      setAlertMessage(undefined);
      return;
    }
    setAlertMessage({
      text: "Failed loading issues",
      severity: "error",
    });
  }, [loadingState]);

  const sortedComponentCategories = Object.values(ComponentCategory).filter(
    (key) => typeof key === "number"
  ) as ComponentCategory[];
  sortedComponentCategories.sort((a, b) => {
    const issuesCountA = siteActiveIssuesByCategory?.[a]?.length ?? 0;
    const issuesCountB = siteActiveIssuesByCategory?.[b]?.length ?? 0;
    return issuesCountB - issuesCountA;
  });

  return (
    <div className={classes.overflow}>
      <div className={classes.content}>
        <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
        {loadingState !== LoadingState.Error &&
          sortedComponentCategories.map((componentCategory, index) => (
            <IssuesSection
              isLoading={loadingState == LoadingState.Pending}
              expanded={index === 0}
              key={componentCategory}
              category={componentCategory}
              issues={siteActiveIssuesByCategory?.[componentCategory] || []}
            />
          ))}
      </div>
    </div>
  );
};

export default Issues;
